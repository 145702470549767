import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgEarlyOperationCover from "../../assets/images/journey/early-operation/early-operation-cover.png"

const EarlyOperationPage = () => (
  <Layout lang="ar">
    <SEO title="Early Operation" lang="ar" />
    <main>
      <div className="uk-cover-container" uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #D9A032"}}>     
        <img src={imgEarlyOperationCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
            <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                  التشغيل المبكر                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  بمجرد إتمام المرحلة الثانية من عملية الإطلاق ستبدأ سلسلة من الأوامر المعدة مسبقاً لتنشيط المسبار، تبدأ بتنشيط الكمبيوتر المركزي، وتشغيل السخانات لمنع تجمد الوقود. وتستمر سلسلة الأوامر فيتم نشر الألواح الشمسية واستخدام مستشعرات مخصصة لتحديد موقع الشمس وتبدأ بعدها مناورة تعديل موضع المسبار وتوجيه الألواح الشمسية نحو الشمس لتبدأ عملية شحن البطاريات الموجودة على متن المسبار.
                  </p>
                  <p>
                  وبمجرد تنشيط المسبار وبالتزامن مع بدء سلسلة الأوامر سيبدأ "مسبار الأمل" في إرسال إشارة إلى الأرض، هذه الإشارة سيتم التقاطها من قِبل شبكة ناسا لمراقبة الفضاء العميق التي تقع في العاصمة الإسبانية مدريد. وبمجرد تلقي الإشارة في المحطة الأرضية سيبدأ فريق مشروع الإمارات لاستكشاف المريخ في إجراء سلسلة من الفحوصات للتأكد من سلامة المسبار.
                  </p>
                  <p>
                  بعد إتمام عملية الاتصال والتأكد من سلامة المسبار، يمكن للفريق الاطمئنان لأن نظام التحكم سيعمل على توجيه المسبار في الاتجاه الصحيح، بينما سيضمن نظام الدفع إجراء مناورات تفصيلية لتحسين مسار "مسبار الأمل" نحو المريخ.
                  </p>
                  <p>
                  الآن يمكن للفريق أن يبدأ في الاستعداد للمراحل المقبلة من المهمة، وذلك لأن "مسبار الأمل" أصبح جاهزاً ليقطع رحلته الطويلة إلى المريخ والتي تستغرق 7 أشهر وتمتد إلى 493,500,000 كيلومتر.
                  </p>
                </div>
            </div>  
                  
          </div>  
      </section>
    </main>
  </Layout>
)

export default EarlyOperationPage
